function readyFn(jQuery) {
  // Show / Hide AppCase JSON Data
  $("#toggle-json-data").click(function() {
    $('#json-data').toggle();

    if ($('#toggle-json-data').text() === 'Show Data') {
      $('#toggle-json-data').text('Hide Data')
    }
    else {
      $('#toggle-json-data').text('Show Data')
    }
  });

  // Toggle Muaitaer/Bo Park/Trevor Documents
  $("#radio-muaitaer").click(function(e) {
    $('input[type=checkbox]').prop('checked', false);

    $('#documents-list-muaitaer').show();
    $('#documents-list-bopark').hide();
    $('#documents-list-trevor').hide();
  });

  $("#radio-bopark").click(function(e) {
    $('input[type=checkbox]').prop('checked', false);

    $('#documents-list-bopark').show();
    $('#documents-list-muaitaer').hide();
    $('#documents-list-trevor').hide();
  });

  $("#radio-trevor").click(function(e) {
    $('input[type=checkbox]').prop('checked', false);

    $('#documents-list-trevor').show();
    $('#documents-list-muaitaer').hide();
    $('#documents-list-bopark').hide();
  });

};

$(document).on('turbolinks:load', readyFn);
